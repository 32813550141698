<script>
const appConfig = {
  //
  appName: "DigiBOX PRO",
  apiClientAppName: "DigiBOX PRO VueJS",
  appCurrency: "XAF",
  //
  appWholeNumberFormat: '0,0',
  appDecimalNumerFormat: '0,0.00',
  appDefaultFormDateTimeFormat: 'YYYY-MM-DD HH:mm:ss',
  appDefaultFormDateFormat: 'YYYY-MM-DD',
  appDefaultFormTimeFormat: 'HH:mm:ss',
  appDefaultDisplayDateTimeFormat: 'DD-MM-YYYY HH:mm',
  appDefaultDisplayDateFormat: 'DD-MM-YYYY',
  appDefaultDisplayTimeFormat: 'HH:mm',
  //Pusher
  pusherBeamsChannelPrefix: "digibox-pro-lcc",
  pusherApiKey: '955e59751c90763b1885',
  pusherCluster: 'eu',
  //Google Maps
  gMapApiKey: "AIzaSyA0IaAHvZJ-VsZv8oMKQXKWY2V1vTO6Uro",
  mapsCenterLat : -2.588631,
  mapsCenterLng : 15.007324,
};
export default appConfig;
</script>
