<script>
/*
 * à, â, æ , ç , é, è, ê, ë , î, ï, ô, œ ù, û, ü
 */
import LocalStorage from '../store/local-storage'
import Login from '../pages/auth/Login.vue'
import Verify from '../pages/auth/Verify.vue'
import Otp from '../pages/auth/Otp.vue'
import Register from '../pages/auth/Register.vue'
import RegistrationOtp from '../pages/auth/RegistrationOtp.vue'
import Home from '../pages/Home.vue'
import About from '../pages/About.vue'
import Contacts from '../pages/Contacts.vue'
import TermsAndConditions from '../pages/TermsAndConditions.vue'

const routes = [
  //Auth
  {
    name: 'login',
    path: '/login',
    component: Login,
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next('/dashboard');
      } else {
        next()
      }
    }
  },
  {
    name: 'verify',
    path: '/verify',
    component: Verify,
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next('/dashboard');
      } else {
        next()
      }
    }
  },
  {
    name: 'otp',
    path: '/otp/:phone/:name',
    component: Otp,
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next('/dashboard');
      } else {
        next()
      }
    }
  },

  //Register
  {
    name: 'register',
    path: '/register',
    component: Register,
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next('/dashboard');
      } else {
        next()
      }
    }
  },
  {
    name: 'registration-otp',
    path: '/registration-otp/:phone/:name',
    component: RegistrationOtp,
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next('/dashboard');
      } else {
        next()
      }
    }
  },

  //Guest
  {
    name: 'home',
    path: '/',
    component: Home,
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next('/dashboard');
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'about',
    path: '/about',
    component: About,
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next('/dashboard');
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'contacts',
    path: '/contacts',
    component: Contacts,
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next('/dashboard');
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'terms-and-conditions',
    path: '/terms-and-conditions',
    component: TermsAndConditions,
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next('/dashboard');
      } else {
        next('/login')
      }
    }
  },


  //Secured
  {
    name: 'dashboard',
    path: '/dashboard',
    component: function () {
      return import('../pages/sales/SalesDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'dashboard-daily-report',
    path: '/dashboard/daily-report',
    component: function () {
      return import('../pages/dashboard/DailyReport.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  //
  {
    name: 'prospection',
    path: '/prospection',
    component: function () {
      return import('../pages/prospection/ProspectionDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  //
  {
    name: 'sales',
    path: '/sales',
    component: function () {
      return import('../pages/sales/SalesDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  //
  {
    name: 'vaults',
    path: '/vaults',
    component: function () {
      return import('../pages/vaults/VaultsDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  //
  {
    name: 'scm',
    path: '/scm',
    component: function () {
      return import('../pages/scm/SCMDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  //
  {
    name: 'production',
    path: '/production',
    component: function () {
      return import('../pages/production/ProductionDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  //
  {
    name: 'stock',
    path: '/stock',
    component: function () {
      return import('../pages/stock/StockDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  //
  {
    name: 'monitoring',
    path: '/monitoring',
    component: function () {
      return import('../pages/monitoring/MonitoringDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'monitoring-prospection',
    path: '/monitoring/prospection',
    component: function () {
      return import('../pages/monitoring/MonitoringProspectionDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },

  {
    name: 'monitoring-sales',
    path: '/monitoring/sales',
    component: function () {
      return import('../pages/monitoring/WarehousePeriodicSalesReportDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'monitoring-sales-periodic-report-per-warehouse',
    path: '/monitoring/sales/sales-periodic-report-per-warehouse',
    component: function () {
      return import('../pages/monitoring/WarehousePeriodicSalesReportDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'monitoring-sales-clients-account-summary',
    path: '/monitoring/sales/clients-account-summary',
    component: function () {
      return import('../pages/monitoring/LocationClientsAccountSummaryDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'monitoring-sales-invoice-ageing',
    path: '/monitoring/sales/invoice-ageing',
    component: function () {
      return import('../pages/monitoring/LocationInvoiceAgeingDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'monitoring-sales-sleeping-clients-on-list',
    path: '/monitoring/sales/sleeping-clients-on-list',
    component: function () {
      return import('../pages/monitoring/LocationSleepingClientsOnListDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'monitoring-sales-sleeping-clients-on-map',
    path: '/monitoring/sales/sleeping-clients-on-map',
    component: function () {
      return import('../pages/monitoring/LocationSleepingClientsOnMapDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'monitoring-sales-sales-orders',
    path: '/monitoring/sales/sales-orders',
    component: function () {
      return import('../pages/monitoring/WarehouseSalesOrders.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'monitoring-sales-order-payments',
    path: '/monitoring/sales/order-payments',
    component: function () {
      return import('../pages/monitoring/WarehouseOrderPayments.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },

  {
    name: 'monitoring-vaults',
    path: '/monitoring/vaults',
    component: function () {
      return import('../pages/monitoring/MonitoringVaultsDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'monitoring-scm',
    path: '/monitoring/scm',
    component: function () {
      return import('../pages/monitoring/MonitoringSCMDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'monitoring-production',
    path: '/monitoring/production',
    component: function () {
      return import('../pages/monitoring/MonitoringProductionDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },

  {
    name: 'monitoring-stock',
    path: '/monitoring/stock',
    component: function () {
      return import('../pages/monitoring/WarehousePeriodicStockReportDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'monitoring-stock-periodic-report-per-warehouse',
    path: '/monitoring/stock/stock-periodic-report-per-warehouse',
    component: function () {
      return import('../pages/monitoring/WarehousePeriodicStockReportDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'monitoring-stock-periodic-report-per-warehouse-product',
    path: '/monitoring/stock/stock-periodic-report-per-warehouse-product',
    component: function () {
      return import('../pages/monitoring/WarehouseProductPeriodicStockTransactionsReport.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'monitoring-stock-stock-orders',
    path: '/monitoring/stock/stock-orders',
    component: function () {
      return import('../pages/monitoring/WarehouseStockOrders.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'monitoring-stock-stock-orders-debit',
    path: '/monitoring/stock/stock-orders-debit',
    component: function () {
      return import('../pages/monitoring/WarehouseStockOrdersDebit.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },

  {
    name: 'monitoring-admin',
    path: '/monitoring/admin',
    component: function () {
      return import('../pages/monitoring/MonitoringAdminDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  //
  {
    name: 'admin',
    path: '/admin',
    component: function () {
      return import('../pages/admin/AdminDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },

  {
    name: 'admin-products',
    path: '/admin/products',
    component: function () {
      return import('../pages/admin/ProductsDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'admin-products-create',
    path: '/admin/products/create',
    component: function () {
      return import('../pages/admin/ProductCreate.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'admin-products-import',
    path: '/admin/products/import',
    component: function () {
      return import('../pages/admin/ProductImport.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'admin-products-export',
    path: '/admin/products/export',
    component: function () {
      return import('../pages/admin/ProductExport.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },

  {
    name: 'admin-clients',
    path: '/admin/clients',
    component: function () {
      return import('../pages/admin/ClientsDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'admin-clients-create',
    path: '/admin/clients/create',
    component: function () {
      return import('../pages/admin/ClientCreate.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'admin-clients-import',
    path: '/admin/clients/import',
    component: function () {
      return import('../pages/admin/ClientImport.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'admin-clients-export',
    path: '/admin/clients/export',
    component: function () {
      return import('../pages/admin/ClientExport.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },

  //
  {
    name: 'admin-exports',
    path: '/admin/exports',
    component: function () {
      return import('../pages/admin/ExportsDashboard.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'admin-exports-excel',
    path: '/admin/exports/excel',
    component: function () {
      return import('../pages/admin/ExcelExports.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'admin-exports-csv',
    path: '/admin/exports/csv',
    component: function () {
      return import('../pages/admin/CSVExports.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'admin-exports-custom',
    path: '/admin/exports/custom',
    component: function () {
      return import('../pages/admin/CustomExports.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },

  //Other//Other
  {
    name: 'playground',
    path: '/playground',
    component: function () {
      return import('../pages/playground/Playground.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  {
    name: 'query-playground',
    path: '/query-playground',
    component: function () {
      return import('../pages/playground/QueryPlayground.vue');
    },
    beforeEnter: (to, from, next) => {
      if (LocalStorage.hasApiToken()) {
        next();
      } else {
        next('/login')
      }
    }
  },
  //Temporary Integration Routes
  //
  {
    path: '*',
    redirect: '/playground'
  }
];

export default routes;
</script>
