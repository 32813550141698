<script>
    const apiConfig = {
        //authBaseURI: 'http://localhost:8000/api-v1/auth',
        //appBaseURI: 'http://localhost:8000/api-v1/web-api/pos',
        //authBaseURI: 'http://192.168.0.154:8000/api-v1/auth',
        //appBaseURI: 'http://192.168.0.154:8000/api-v1/web-api/pos',
        //authBaseURI: 'https://lcc-dev.digibox-pos.com/api-v1/auth',
        //appBaseURI: 'https://lcc-dev.digibox-pos.com/api-v1/web-api/pos',
        authBaseURI: 'https://lcc-prod.digibox-pos.com/api-v1/auth',
        appBaseURI: 'https://lcc-prod.digibox-pos.com/api-v1/web-api/pos',
    };
    export default apiConfig;
</script>
